import ru from '@/locales/ru.json';
import en from '@/locales/en.json';

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'en',
    fallbackLocale: 'ru',
    messages: {
      en,
      ru
    }
  };
});
